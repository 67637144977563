var addressValidation4i = function () {
    var validateAddress = function (onSuccess) {
        var form = document.querySelector('.addressValidation24');
        var address1, address2, city, state, zipCode;

        if (form) {
            try {
                let addressData = new FormData();
                addressData.append('id', "address1");
                addressData.append('address1', form.querySelector('input[name="Address1"]').value);
                addressData.append('address2', form.querySelector('input[name="Address2"]').value);
                addressData.append('city', form.querySelector('input[name="City"]').value);
                addressData.append('provinceOrState', form.querySelector('select[name="State"] option:checked').value);
                addressData.append('postalOrZip', form.querySelector('input[name="ZipCode"]').value);

                fetch('/address/verifyAddresses', {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'fetch'
                    },
                    body: addressData
                }).then(function (response) {
                    return (response.ok) ? response.json() : [];
                }).then(function (response) {
                    //If the address is not verified, display the validation prompt
                    if (response && response.success && !response.verified) {

                        document.querySelector('#addressValidationPrompt').innerHTML = response.validationPrompt;
                        document.querySelector('#addressValidationPrompt').classList.remove('hide');

                        address1 = response.address1;
                        address2 = (response.address2 !== "null") ? response.address2 : '';
                        city = response.city;
                        state = response.provinceOrState;
                        zipCode = response.postalOrZip;

                        document.querySelector('#acceptSuggestion').addEventListener('click', changeAddress);
                        document.querySelector('#editAddressVal').addEventListener('click', function (e) {
                            e.preventDefault()
                            document.querySelector('input[name="Address1"]').focus();
                        });
                    }
                    //Else if the address is verified, update the form with the verified address and continue
                    else if (response && response.success && response.verified) {

                        form.querySelector('input[name="Address1"]').value = response.address1;
                        form.querySelector('input[name="Address2"]').value = response.address2;
                        form.querySelector('input[name="City"]').value = response.city;
                        form.querySelector('select[name="State"]').value = response.provinceOrState;
                        form.querySelector('input[name="ZipCode"]').value = response.postalOrZip;

                        console.log('Address Validated');

                        onSuccess();
                    }
                    else
                    {
                        onSuccess(); // address failed, continue
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            onSuccess(); // no address form, continue
        }

        var changeAddress = function (e) {
            e.preventDefault();
            form.querySelector('input[name="Address1"]').value = address1;
            form.querySelector('input[name="Address2"]').value = address2;
            form.querySelector('input[name="City"]').value = city;
            form.querySelector('select[name="State"]').value = state;
            form.querySelector('input[name="ZipCode"]').value = zipCode;
            hideAddressValidationPrompt();
            
            onSuccess();
        };
    };

    var validateCheckoutAddress = async function () {
        var checkoutAddress = document.querySelector('.checkoutAddressValidation24');
        if (checkoutAddress) {

            var addressId = checkoutAddress.querySelector('#addressId').innerHTML;
            var gurid = document.querySelector('#UserCart_GURID').value;
            var address1, address2, city, state, zipCode;

            try {
                //Check first to see if the address has already been validated before running a new validation
                let isValidated = await getAddressValidationStatus(gurid, addressId);

                if (checkoutAddress && !isValidated) {
                    let addressData = new FormData();
                    addressData.append('id', "address1");
                    addressData.append('address1', checkoutAddress.querySelector('p[id="address1"]').innerHTML);
                    addressData.append('address2', checkoutAddress.querySelector('p[id="address2"]').innerHTML);
                    var cityStatePostal = checkoutAddress.querySelector('p[id="cityStatePostal"]').innerHTML.split('&nbsp;');
                    if (cityStatePostal[0].endsWith(',')) { //remove ',' from city
                        cityStatePostal[0] = cityStatePostal[0].slice(0, -1);
                    }
                    addressData.append('city', cityStatePostal[0]);
                    addressData.append('provinceOrState', cityStatePostal[1]);
                    addressData.append('postalOrZip', cityStatePostal[2]);

                    fetch('/address/verifyAddresses', {
                        method: 'POST',
                        headers: {
                            'X-Requested-With': 'fetch'
                        },
                        body: addressData
                    }).then(function (response) {
                        return (response.ok) ? response.json() : [];
                    }).then(function (response) {
                        if (response) {
                            address1 = response.address1;
                            address2 = (response.address2 !== "null") ? response.address2 : '';
                            city = response.city;
                            state = response.provinceOrState;
                            zipCode = response.postalOrZip;
                            //If the address is not verified, display the validation prompt
                            if (response.success && !response.verified) {
                                document.querySelector('#addressValidationPrompt').innerHTML = response.validationPrompt;
                                document.querySelector('#addressValidationPrompt').classList.remove('hide');

                                document.querySelector('#acceptSuggestion').addEventListener('click', changeAddress);
                                document.querySelector('#editAddressVal').addEventListener('click', function () {
                                    document.getElementById("editShippingAddress").click();
                                });
                            }
                            //Else if the address is verified, update address
                            else if (response.success && response.verified) {
                                changeAddress(); 
                                //console.log('Address Verified');
                            }

                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            } catch (error) {
                console.log(error);
            }

            var changeAddress = function (e) {
            
                if (e) e.preventDefault();
                const addressInfo = [].slice.call(document.querySelectorAll('.checkoutAddressValidation24, #divShipAddress_' + addressId));
                //Change the address on the checkout page
                addressInfo.forEach(function (element) {
                    if (element.querySelector('p[id="address1"]')) {
                        element.querySelector('p[id="address1"]').innerHTML = address1;
                    }
                    if (element.querySelector('p[id="address2"]')) {
                        element.querySelector('p[id="address2"]').innerHTML = address2;
                    }
                    if (element.querySelector('p[id="cityStatePostal"]')) {
                        element.querySelector('p[id="cityStatePostal"]').innerHTML = city + ',&nbsp;' + state + '&nbsp;' + zipCode;
                    }
                });

                hideAddressValidationPrompt();

                var address = {
                    AddressId: addressId,
                    Address1: address1,
                    Address2: address2 ?? '',
                    City: city,
                    State: state,
                    ZipCode: zipCode,
                    GurId: gurid
                }
                //Update SOP_address table with validation status
                updateAddressValidationStatus(address, 'V');
            };
        }
    };

    var getAddressValidationStatus = async function (gurid, addressId) {
        var validationStatus = false;

        try {
            var data = new FormData();
            data.append('addressId', addressId);
            data.append('gurid', gurid);

            const response = await fetch('/address/getAddressValidation', {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'fetch',
                },
                body: data
            });

            if (response.ok) {
                const responseData = await response.json();
                if (responseData && responseData.success) {
                    validationStatus = responseData.isValidated === 'V' ? true : false;
                }
            }
        } catch (error) {
            console.log(error);
        }

        return validationStatus;
    };

    var updateAddressValidationStatus = function (address, status) {
        try {

            let data = new FormData();
            data.append('AddressId', address.AddressId);
            data.append('Address1', address.Address1);
            data.append('Address2', address.Address2);
            data.append('City', address.City);
            data.append('State', address.State);
            data.append('ZipCode', address.ZipCode);
            data.append('GurId', address.GurId);
            data.append('Validated', status);

            fetch('/address/updateAddressValidation', {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'fetch'
                },
                body: data
            }).then(function (response) {
                return (response.ok) ? response.json() : [];
            }).then(function (response) {
                if (response && !response.success) {
                    throw new Error('Address validation status update failed');
                }
            }).catch(function (error) {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    };
    
    var hideAddressValidationPrompt = function () {
        if (document.querySelector('#addressValidationPrompt')) {
            document.querySelector('#addressValidationPrompt').classList.add('hide');
        }
    };


    return {
        validateAddress: validateAddress,
        validateCheckoutAddress: validateCheckoutAddress,
        updateAddressValidationStatus: updateAddressValidationStatus,
        hideAddressValidationPrompt: hideAddressValidationPrompt
    };
}