var formAbandonment4i = function(){
	let elementsVisited = [];
	
	const init = () => {
		window.addEventListener('beforeunload', checkIfAbandoned);
	};
	 
	const checkIfAbandoned = () =>{
	    	if (!checkSubmit()) {
	      		//form was not submitted, check for last field that was accessed
	      		if(elementsVisited.length > 0){
	        		for(let i = 0; i < elementsVisited.length; i++){
	          			if(elementsVisited[i].nodeName.toLowerCase() === 'input'){       	
	            				if(elementsVisited[i].className.includes('FormAbandonmnetLog')){ //TODO this needs to be spelled correctly, need to fix
	              				omnitureLog(elementsVisited[i].name);
	              				break;//last input field found. Stop processing and exit loop
	            				}
	          			}
	        		}//end for loop
	      		}     
	    	}
	};
	
	//form : Form being logged
	//s.prop : Traffic variable set in adobe for the form
	//elementName : Element last accessed before the form was abandoned
	const omnitureLog = (elementName) => {      
		const sampleRequestForm = document.getElementById('SampleRequestForm');
		const registrationForm = document.getElementById('registerForm');
		const checkoutForm = document.getElementById('CheckoutForm');
		const checkoutLoginForm = document.getElementById('checkoutRegisterForm');
		const account = s_account ? s_account : '4imprintprod';
		const s = s_gi(account);
	     	if (typeof s != 'undefined') {
	     		if(sampleRequestForm != null){
	     	  		s.linkTrackVars = 'prop30';
	         		s.linkTrackEvents = 'None';
	         		s.prop30 = elementName;
	         		s.tl(true, 'o', "SampleRequestAbandoned");
	     		} else if(registrationForm != null){
	      	  		s.linkTrackVars = 'prop31';
	         		s.linkTrackEvents = 'None';
	         		s.prop31 = elementName;
	         		s.tl(true, 'o', "RegistrationAbandoned");    	
	     		}else if(checkoutLoginForm != null){
	     	  		s.linkTrackVars = 'prop33';
	        		s.linkTrackEvents = 'None';
	         		s.prop33 = elementName;
	         		s.tl(true, 'o', "CheckoutLoginAbandoned");     	
	     		}else if(checkoutForm != null){
	     	  		s.linkTrackVars = 'prop32';
	         		s.linkTrackEvents = 'None';
	         		s.prop32 = elementName;
	         		s.tl(true, 'o', "CheckoutAbandoned");     	
	     		}
	     	}     
	}; 
	  
	const checkSubmit = () => {
	    	let dlSize = -1;
	    	if (window.dataLayer) dlSize = window.dataLayer.length -1;
	    	while (dlSize > -1) {
	      		if (window.dataLayer[dlSize].event === 'gtm.formSubmit') {
	        		//form was submitted, safe to exit 
	        		return true;
	      		} else if (window.dataLayer[dlSize].event === 'gtm.click'){
	        		//get all elements the user clicked on
	        		const element = window.dataLayer[dlSize]['gtm.element'];
	        		if(element.classList.contains('BtnSubmitForm')) return true;
	        		elementsVisited.push(element);
	      		}    
	      		dlSize--;
	    	}
	    	//form was abandoned
	    	return false;
	};
	
	return{
		Init: init
	};
};

(async () => {
	const formAbandon4i = new formAbandonment4i();
	formAbandon4i.Init();
})();